// @file Query client which supports the stale-while-revalidate strategy by returning cached data immediately and then fetching the latest data in the background.
import window from '@@/bits/global'
import type { LibraryId, TenantId, UserId } from '@@/types'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { persistQueryClient, removeOldestQuery } from '@tanstack/query-persist-client-core'
import type { QueryFunction } from '@tanstack/vue-query'
import { QueryClient } from '@tanstack/vue-query'
import { del, get, set } from 'idb-keyval'

export const getQueryClient = (): QueryClient | null => window?.queryClient ?? null

export async function createQueryClient(): Promise<QueryClient> {
  const dataVersion = document.documentElement.getAttribute('data-version') ?? ''
  const queryClient = new QueryClient({
    defaultOptions: { queries: { cacheTime: 1000 * 60 * 60 * 24 } },
  })
  const persister = createAsyncStoragePersister({
    storage: {
      setItem: set,
      getItem: get as (key: string) => Promise<string | null>,
      removeItem: del,
    },
    key: process.env.NODE_ENV === 'production' ? 'PADLET_OFFLINE_CACHE' : 'DEV_PADLET_OFFLINE_CACHE',
    throttleTime: 0,
    retry: removeOldestQuery,
  })

  const [, restorePromise] = persistQueryClient({
    queryClient,
    persister,
    buster: dataVersion, // Invalidate the cache on every deployment
  })
  // Wait for the persisted cache to be restored
  await restorePromise

  return queryClient
}

/**
 * Handles fetching a query with caching by employing the stale-while-revalidate strategy.
 *
 * See https://tanstack.com/query/v4/docs/reference/QueryClient/#queryclientfetchquery
 *
 * @param {any} cacheKey: A unique key used to cache and retrieve data.
 * @param {any} queryFn: A function that fetches data from the server.
 * @param {any} onCacheFetchSuccess: An optional callback function that is invoked with cached data when available.
 * @param {any} onFetchSuccess: A callback function that is invoked when the query successfully retrieves data, applicable for both server responses and cached data if onCacheFetchSuccess is undefined.
 * @returns {any} A void promise
 */
export async function fetchCachedQuery<T>({
  cacheKey,
  queryFn,
  onCacheFetchSuccess,
  onFetchSuccess,
}: {
  cacheKey: Array<string | number>
  queryFn: Function
  onCacheFetchSuccess?: (response: T) => void
  onFetchSuccess: (response: T) => void
}): Promise<void> {
  const queryClient = getQueryClient()
  if (queryClient == null) {
    const response = await queryFn()
    void onFetchSuccess(response)
    return
  }

  const cachedData: T | undefined = queryClient.getQueryData(cacheKey)
  if (cachedData != null) {
    // optimistically handle the cached data
    if (onCacheFetchSuccess !== undefined) {
      void onCacheFetchSuccess(cachedData)
    } else {
      void onFetchSuccess(cachedData)
    }
  }

  // fetch to revalidate
  // Note: since the default `staleTime` is 0, the query will always fetch
  // @see https://tanstack.com/query/v4/docs/framework/vue/guides/initial-query-data
  const response: T = await queryClient.fetchQuery({
    queryKey: cacheKey,
    queryFn: queryFn as QueryFunction,
  })

  void onFetchSuccess(response)
}

/**
 * Re-fetches the queries with the given cache key.
 * See https://tanstack.com/query/v4/docs/reference/QueryClient/#queryclientrefetchqueries
 */
export async function refetchCachedQueries({
  cacheKey,
  exact = true,
}: {
  cacheKey: Array<string | number>
  exact?: boolean
}): Promise<void> {
  const queryClient = getQueryClient()
  if (queryClient == null) return

  await queryClient.refetchQueries({
    queryKey: cacheKey,
    exact,
  })
}

/**
 * Clears all queries and mutations from the cache.
 * See https://tanstack.com/query/v4/docs/reference/QueryClient/#queryclientclear
 */
export function clearCachedQueries(): void {
  const queryClient = getQueryClient()
  if (queryClient == null) return
  queryClient.clear()
}

export function buildUserIdCacheKey(userId: UserId): string {
  return `userId:${userId}`
}

export function buildTenantIdCacheKey(tenantId: TenantId): string {
  return `tenantId:${tenantId}`
}

export function buildLibraryIdCacheKey(libraryId: LibraryId): string {
  return `libraryId:${libraryId}`
}
